import { Classes } from "./classes.model";

export class Batch {
  public batchId: string = "";
  public batchName: string = "";
  public academicTypeId: string = "";
  public academicYearId: string = "";
  public academicYear: string = "";
  public class: Classes[];
  public status: string = "";
}
