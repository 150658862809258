import { Injectable } from "@angular/core";
import { Adapter } from "app/core/adapters";
import { IClassesAPI } from "../model/classapi.model";
import { ClassAdapter } from "./class.adapter.service";
@Injectable({
  providedIn: "root",
})

export class ClassApiAdapter implements Adapter<IClassesAPI> {

  constructor(private adapter: ClassAdapter) {}

  adapt(response: any): IClassesAPI {
    let objList = [];
    response.data.forEach((row) => {
      objList.push(this.adapter.adapt(row));
    });

    return {
      keyword: response.keyword,
      message: response.message,
      classes: objList,
      pageSize: response.total_size,
      page: response.page,
    };
  }
}
