export const routeModules = {
    school: [{
        givenModule: 'leave-request',
        actualModule: 'employee/leave-request',
        path: [{
            givenPath: 'view', actualPath: 'view', isSingleIdRequired: true,
            givensingleIdName: 'leave_request_id', isMultipleIdRequired: false, givenMulipleIds: []
        }]
    }],
    parent: [{
        givenModule: 'leave-request',
        actualModule: 'employee/leave-request',
        path: [{
            givenPath: 'view', actualPath: 'view', isSingleIdRequired: true,
            givensingleIdName: 'leave_request_id', isMultipleIdRequired: false, givenMulipleIds: []
        }]
    }],
    teacher: [{
        givenModule: 'leave-request',
        actualModule: 'leave-request',
        path: [{
            givenPath: 'view', actualPath: 'view', isSingleIdRequired: true,
            givensingleIdName: 'leave_request_id', isMultipleIdRequired: false, givenMulipleIds: []
        }]
    }]
}