import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject,Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { saveToken, destroyToken, hasToken, decodedToken, clearSessionData } from '../helpers/token.helper';
import { Teacher, ITeacherAPI } from '../models/';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { ApiAdapter } from './teacher/api.adapter.service';
import { get } from "lodash";
import { PORTALTYPE } from "../helpers/enum.helper";
import { constant, DEFAULT_LANG } from '../helpers/global.helper';


@Injectable({
  providedIn: 'root'
})

export class TeacherService {
 
  public currentBatch = new Subject();
  private currentTeacherSubject = new BehaviorSubject<Teacher>({} as Teacher);
  public currentTeacher = this.currentTeacherSubject.asObservable().pipe(distinctUntilChanged());
  public currentBatchesSubject = new BehaviorSubject<any>({});
  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  portalType = '';
  constructor(
    private apiService: ApiService,
    private router: Router,
    private adapter: ApiAdapter,
  ) {

    this.isAuthenticatedSubject.next(hasToken());
    this.currentTeacherSubject.next(this.getSessionUser());

  }

  // Verify JWT in localstorage with server & load user's info.
  // This runs once on application startup.
  signin(model: Teacher): Observable<ITeacherAPI> {
    let formData = new FormData();
    formData.append('username', model.email);
    formData.append('password', model.password);
    return this.apiService.post('teacher/login', formData).pipe(map(response => this.adapter.adapt(response)));
  }

  otpVerify(model: Teacher): Observable<ITeacherAPI> {
    let formData = new FormData();
    formData.append('mobile', model.mobile);
    return this.apiService.post('teacher/login', formData).pipe(map(response => this.adapter.adapt(response)));
  }

  forgetpassword(model: Teacher): Observable<ITeacherAPI> {
    let formData = new FormData();
    formData.append('email', model.email);
    return this.apiService.post('teacher/forget', formData);
  }


  setAuth(teacher: Teacher) {
    // Save JWT sent from server in localstorage
    saveToken(teacher.token);
    window.localStorage.setItem('sesUsr', JSON.stringify(teacher));
    // Set current parent data into observable
    this.currentTeacherSubject.next(teacher);
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
  }

  purgeAuth() {
    // Remove JWT from localstorage
    destroyToken();
    clearSessionData();
    // Set current user to an empty object
    this.currentTeacherSubject.next({} as Teacher);
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
  }


  sendResetLink(model: Teacher): Observable<ITeacherAPI> {
    let formData = new FormData();
    formData.append('email', model.email);
    return this.apiService.post('teacher/forget', formData).pipe(map(response => this.adapter.adapt(response)));
  }


  resetPassword(model: Teacher): Observable<ITeacherAPI> {
    const formData = new FormData();
    formData.append('password', model.newPassword);
    formData.append('token', model.token);
    return this.apiService.post('teacher/reset', formData);
  }


  chagePassword(model: Teacher): Observable<ITeacherAPI> {
    const formData = new FormData();
    const token = decodedToken();
    formData.append('old_password', model.oldPassword);
    formData.append('new_password', model.newPassword);
    formData.append('email', get(token, 'aud'));
    return this.apiService.post('teacher/changepassword', formData);
  }



  getCurrentUser(): Teacher {
    return JSON.parse(window.localStorage.getItem('sesUsr'));
  }


  get isLoggedIn() {
    return this.isAuthenticatedSubject.asObservable();
  }

  logout() {
    this.getPortalType();
    this.purgeAuth();
    this.goToLogin();
  }

  getSessionUser(): Teacher {
    return JSON.parse(window.localStorage.getItem('sesUsr'));
  }


  goToLogin() {
    if (this.portalType == PORTALTYPE.CUSTOMER) {
      this.router.navigate(['/']);
    } else if (this.portalType == PORTALTYPE.HOSPITAL) {
      this.router.navigate(['/']);
    } else if (this.portalType == PORTALTYPE.TEACHER) {
      this.router.navigate(['/teacher']);
    } else if (this.portalType == PORTALTYPE.PARENT) {
      this.router.navigate(['/parent']);
    } else {
      this.router.navigate(['/']); //default
    }
  }



  getPortalType() {
    const token = decodedToken();
    this.portalType = get(token, 'type') ?? '';
  }


  setBatchId(batchId?: string) {
    window.localStorage.setItem(constant().app.config.batchId, batchId);
    this.currentBatch.next(batchId);
  }
  setAcademicId(academicId : string) {
    window.localStorage.setItem(constant().app.config.academicId, academicId);
  }
 
  setAcademicName(academicName: any) {
    window.localStorage.setItem(constant().app.config.academicName, academicName);
  }
  setbatchName(batchName: string) {
    window.localStorage.setItem(constant().app.config.batchName, batchName);
  }


  getRecordAddedBatches(): Observable<any[]> {
    return this.currentBatchesSubject.asObservable();
  } 
  getBatchId() {
    return window.localStorage.getItem(constant().app.config.batchId);
  }
  getAcademicId() {
    return window.localStorage.getItem(constant().app.config.academicId);
  }
}