import { Component, OnInit } from '@angular/core';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { UserService } from 'app/core/services/user.service';
import { environment } from 'environments/environment';
import { ConfigService } from 'app/core/services/config.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AngularFireDatabase } from '@angular/fire/database';

@Component({
  selector: "app-patient-details",
  templateUrl: "./patient-details.component.html",
  styleUrls: ["./patient-details.component.css"],

})
export class PatientDetailsComponent implements OnInit {

  payLoad = [];
  stuPayload = [];
  filterPatient = [];
  patientSub: Subscription;
  commonPayload = [];
  customerId: any;
  hospitalId



  constructor(
    private userServ: UserService,
    public dialogRef: MatDialogRef<PatientDetailsComponent>,
    private configServ: ConfigService,
  ) {

    this.configServ.init();
    this.customerId = this.configServ.customerId;
    this.hospitalId = this.configServ.branchId;
  }




  ngOnInit() {
    this.getPatientNoties();
  }





  getPatientNoties() {
    this.patientSub = this.userServ.arrayOfPatientSub
      .subscribe(res => {
        if (res) {
          this.payLoad.unshift(res);
          console.log(this.payLoad, "response")
          this.FilterPatientlist();
          this.commonPayload = [...this.filterPatient];
          this.commonPayload.sort((a, b) => new Date(b.currentdate).getTime() - new Date(a.currentdate).getTime());
        }
      });
  }


  FilterPatientlist() {
    this.filterPatient = this.payLoad.reduce(function (previous, current) {
      var object = previous.filter(object => object.id === current.id);
      if (object.length == 0) {
        previous.push(current);
      }
      return previous;
    }, []);

  }





  ngOnDestroy() {
    this.payLoad = [];
    this.filterPatient = [];
    this.commonPayload = [];
    this.patientSub.unsubscribe();
    this.userServ.arrayOfPatientSub = new ReplaySubject();
  }


  getPatientPhoto(id) {
    return `${environment.imageUrl}public/patient/patient-${this.customerId}-${this.hospitalId}-${id}.png`
  }

  // getPatientPhoto(id) {
  //   return `https://school.petalpurple.in/public/Patient/Patient-${this.customerId}-${this.schoolId}-${id}.png`
  // }


  discard() {
    this.dialogRef.close();
  }


}


