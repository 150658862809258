import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TeacherService } from './teacher.service';
import { take, map } from 'rxjs/operators';
import { CurrentUserService } from './current-user.service';

@Injectable({
  providedIn: 'root'
})
export class TeacherAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private currentUserService: CurrentUserService,
    private teacherService: TeacherService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    return this.teacherService.isLoggedIn.pipe(take(1), map((isLoggedIn: boolean) => {
      let checkOTP = localStorage.getItem('ISOTPVERIFIED') ? true : false;
      let canLogin = isLoggedIn && checkOTP;
      if (!canLogin) {
        // this.router.navigate(['/']); 
        this.teacherService.logout();
        return false;
      }
      return true;
    })
    );
  }
}