import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { saveToken, destroyToken, hasToken, decodedToken, clearSessionData } from '../helpers/token.helper';
import { User, IUserAPI } from '../models/';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { ApiAdapter } from './user/api.adapter.service';
import { get } from "lodash";
import { PORTALTYPE } from "../helpers/enum.helper";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private currentUserSubject = new BehaviorSubject<User>({} as User);
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());
  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  portalType = '';



  // sos notification

  public arrayOfSosSub = new Subject();

  //nomovement notification
  public arrayOfNomovementSub = new ReplaySubject(1000);

  // patient details notification

  public arrayOfPatientSub = new ReplaySubject(1000);


  constructor(
    private apiService: ApiService,
    private router: Router,
    private adapter: ApiAdapter,
  ) {

    this.isAuthenticatedSubject.next(hasToken());
    this.currentUserSubject.next(this.getSessionUser());

  }

  // Verify JWT in localstorage with server & load user's info.
  // This runs once on application startup.
  signin(model: User): Observable<IUserAPI> {
    let formData = new FormData();
    formData.append('username', model.email);
    formData.append('password', model.password);
    return this.apiService.post('hospital/login', formData).pipe(map(response => this.adapter.adapt(response)));
  }

  otpVerify(model: User): Observable<IUserAPI> {
    let formData = new FormData();
    formData.append('mobile', model.mobile);
    return this.apiService.post('hospital/login', formData).pipe(map(response => this.adapter.adapt(response)));
  }

  forgetpassword(model: User): Observable<IUserAPI> {
    let formData = new FormData();
    formData.append('email', model.email);
    return this.apiService.post('hospital/forget', formData);
  }


  setAuth(user: User) {
    // Save JWT sent from server in localstorage
    saveToken(user.token);
    window.localStorage.setItem('sesUsr', JSON.stringify(user));
    // Set current user data into observable
    this.currentUserSubject.next(user);
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
  }

  purgeAuth() {
    // Remove JWT from localstorage
    destroyToken();
    clearSessionData();
    // Set current user to an empty object
    this.currentUserSubject.next({} as User);
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);

  }


  sendResetLink(model: User): Observable<IUserAPI> {
    let formData = new FormData();
    formData.append('email', model.email);
    return this.apiService.post('hospital/forget', formData).pipe(map(response => this.adapter.adapt(response)));
  }


  resetPassword(model: User): Observable<IUserAPI> {
    const formData = new FormData();
    formData.append('password', model.newPassword);
    formData.append('token', model.token);
    return this.apiService.post('hospital/reset', formData);
  }


  chagePassword(model: User): Observable<IUserAPI> {
    const formData = new FormData();
    const token = decodedToken();
    formData.append('old_password', model.oldPassword);
    formData.append('new_password', model.newPassword);
    formData.append('email', get(token, 'aud'));
    return this.apiService.post('hospital/changepassword', formData);
  }

  getCurrentUser(): User {
    return JSON.parse(window.localStorage.getItem('sesUsr'));
  }


  get isLoggedIn() {
    return this.isAuthenticatedSubject.asObservable();
  }

  logout() {
    this.getPortalType();
    this.purgeAuth();
    this.goToLogin();
  }

  getSessionUser(): User {
    return JSON.parse(window.localStorage.getItem('sesUsr'));
  }


  goToLogin() {

    if (this.portalType == PORTALTYPE.CUSTOMER) {
      this.router.navigate(['/']);
    } else if (this.portalType == PORTALTYPE.HOSPITAL) {
      this.router.navigate(['/']);
    } else if (this.portalType == PORTALTYPE.TEACHER) {
      this.router.navigate(['/']);
    } else if (this.portalType == PORTALTYPE.PARENT) {
      this.router.navigate(['/']);
    } else {
      this.router.navigate(['/']); //default
    }

  }



  getPortalType() {
    const token = decodedToken();
    this.portalType = get(token, 'type') ?? '';
  }


  pushSosNotifies(sos) {
    this.arrayOfSosSub.next(sos)
  }

}
