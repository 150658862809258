import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { saveToken, destroyToken, hasToken, decodedToken, clearSessionData } from '../helpers/token.helper';
import { Parent, IParentAPI } from '../models/';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { ApiAdapter } from './parent/api.adapter.service';
import { get } from "lodash";
import { PORTALTYPE } from "../helpers/enum.helper";

@Injectable({
    providedIn: 'root'
  })

  export class ParentService {
    
    private currentParentSubject = new BehaviorSubject<Parent>({} as Parent);
    public currentParent = this.currentParentSubject.asObservable().pipe(distinctUntilChanged());

  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  portalType = '';
  constructor(
    private apiService: ApiService,
    private router: Router,
    private adapter: ApiAdapter,
  ) {

    this.isAuthenticatedSubject.next(hasToken());
    this.currentParentSubject.next(this.getSessionUser());

  }

  // Verify JWT in localstorage with server & load user's info.
  // This runs once on application startup.
  signin(model: Parent): Observable<IParentAPI> {
    let formData = new FormData();
    formData.append('username', model.email);
    formData.append('password', model.password);
    return this.apiService.post('parent/login', formData).pipe(map(response => this.adapter.adapt(response)));
  }

  otpVerify(model: Parent): Observable<IParentAPI> {
    let formData = new FormData();
    formData.append('mobile', model.mobile);
    return this.apiService.post('parent/login', formData).pipe(map(response => this.adapter.adapt(response)));
  }

  forgetpassword(model: Parent): Observable<IParentAPI> {
    let formData = new FormData();
    formData.append('email', model.email);
    return this.apiService.post('parent/forget', formData);
  }


  setAuth(parent: Parent) {
    // Save JWT sent from server in localstorage
    saveToken(parent.token);
    window.localStorage.setItem('sesUsr', JSON.stringify(parent));
    // Set current parent data into observable
    this.currentParentSubject.next(parent);
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
  }

  purgeAuth() {
    // Remove JWT from localstorage
    destroyToken();
    clearSessionData();
    // Set current user to an empty object
    this.currentParentSubject.next({} as Parent);
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);

  }


  sendResetLink(model: Parent): Observable<IParentAPI> {
    let formData = new FormData();
    formData.append('email', model.email);
    return this.apiService.post('parent/forget', formData).pipe(map(response => this.adapter.adapt(response)));
  }


  resetPassword(model: Parent): Observable<IParentAPI> {
    const formData = new FormData();
    formData.append('password', model.newPassword);
    formData.append('token', model.token);
    return this.apiService.post('parent/reset', formData);
  }


  chagePassword(model: Parent): Observable<IParentAPI> {
    const formData = new FormData();
    const token = decodedToken();
    formData.append('old_password', model.oldPassword);
    formData.append('new_password', model.newPassword);
    formData.append('email', get(token, 'aud'));
    return this.apiService.post('parent/changepassword', formData);
  }



  getCurrentUser(): Parent {
    return JSON.parse(window.localStorage.getItem('sesUsr'));
  }


  get isLoggedIn() {
    return this.isAuthenticatedSubject.asObservable();
  }

  logout() {
    this.getPortalType();
    this.purgeAuth();
    this.goToLogin();
  }

  getSessionUser(): Parent {
    return JSON.parse(window.localStorage.getItem('sesUsr'));
  }


  goToLogin() {
    if (this.portalType == PORTALTYPE.CUSTOMER) {
      this.router.navigate(['/']);
    } else if (this.portalType == PORTALTYPE.HOSPITAL) {
      this.router.navigate(['/']);
    } else if (this.portalType == PORTALTYPE.TEACHER) {
      this.router.navigate(['/parent']);
    } else if (this.portalType == PORTALTYPE.PARENT) {
      this.router.navigate(['/parent']);
    } else {
      this.router.navigate(['/']); //default
    }
  }



  getPortalType() {
    const token = decodedToken();
    this.portalType = get(token, 'type') ?? '';
  }



  }