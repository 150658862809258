import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, ActivatedRouteSnapshot, Params, PRIMARY_OUTLET } from "@angular/router";
import { get } from "lodash";
import { PORTALTYPE } from "../helpers/enum.helper";
import { decodedToken } from '../helpers/token.helper';
@Injectable({
  providedIn: 'root'
})
export class RedirectService {
  constructor(private router: Router) { }

  goToDashboard() {
    if (this.portalType == PORTALTYPE.CUSTOMER) {
      this.router.navigate(['/']);
    } else if (this.portalType == PORTALTYPE.HOSPITAL) {
      this.router.navigate(['/']);
    } else if (this.portalType == PORTALTYPE.TEACHER) {
      this.router.navigate(['/']);
    } else if (this.portalType == PORTALTYPE.PARENT) {
      this.router.navigate(['/']);
    }
  }

  get portalType() {
    const token = decodedToken();
    return get(token, 'type') ?? '';
  }

}