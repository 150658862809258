import { Injectable } from "@angular/core";
import { Classes } from "app/shared/models/batch/classes.model";
import { Adapter } from "app/core/adapters";

@Injectable({
    providedIn: "root"
})
export class ClassAdapter implements Adapter<Classes> {
   obj: Classes;
    adapt(item: any): Classes {
        this.obj =  new Classes();  
        this.obj.classAssignId = item?.batch_class_assign_id ?? '';
        this.obj.classId= item?.class_id ?? '';
        this.obj.className= item?.class_name ?? '';
        return this.obj;
    }
}