<mat-card class="p-0">
   <mat-card-title class="">
      <div class="card-title-text">Employee Nomovement - Notification</div>
      <mat-divider></mat-divider>
   </mat-card-title>
   <mat-card-content class="mb-0 p-18 scroll-block">

      <mat-card class="box-shadow  mb-12" *ngFor="let pay of commonPayload">
         <!-- For Employee -->

         <div fxLayout="row wrap" class="mt-0">
            <div fxFlex="100" fxFlex.gt-xs="9">
               <img [src]="getEmployeePhoto(pay?.employeeId)" alt="" class="contact_person_detail">
            </div>
            <div fxFlex="100" fxFlex.gt-xs="56" class="pt-8">
               <h2><b>{{pay?.employeeName}}</b></h2>
               <h4>{{pay?.employeeCode}}</h4>
               <h4>{{pay?.employeeDepartmentName}} - {{pay?.employeePositionName}}</h4>
            </div>

            <div fxFlex="100" fxFlex.gt-xs="35" class="pt-16">
               <div class="display-flex">
                  <mat-icon>place</mat-icon>
                  <span class="mb-8 pr-12">{{pay?.layoutCategoryName}} </span> - <span
                     class="pl-12 pr-12">{{pay?.layoutPositionName}}</span> {{pay?.layoutRoomName ? '-' : ''}} <span
                     class="pl-12">{{pay?.layoutRoomName}}</span>
               </div>
               <div class="display-flex">
                  <mat-icon>schedule</mat-icon>
                  <span>{{pay?.timeStamp | displayDateTime}}<span
                        class="pl-24">{{pay?.timeStamp | displayDate}}</span></span>
               </div>
            </div>
         </div>
      </mat-card>

   </mat-card-content>
   <mat-card-footer class="pt-24">
      <a (click)="discard()" class="pl-8 primary-txt-color">Discard</a>
   </mat-card-footer>

</mat-card>