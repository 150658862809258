import { Injectable } from '@angular/core';
import { ApiService } from 'app/core/services/api.service';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private apiService: ApiService,) { }

  getCountries() {
    return this.apiService.get(`country`);
  }

  getStates(country_id) {
    let params = new HttpParams()
    params = params.append('country_id', country_id)
    return this.apiService.get(`state`, params);
  }

  getCities(state_id) {
    let params = new HttpParams()
    params = params.append('state_id', state_id)
    return this.apiService.get(`city`, params);
  }

  getNationality() {
    return this.apiService.get(`nationality`);
  }

  getReligion() {
    return this.apiService.get(`religion`);
  }

  getRelationship() {
    return this.apiService.get(`relationship`);
  }

  getBloodGroup() {
    return this.apiService.get(`bloodGroup`);
  }
  getMotherTongue() {
    return this.apiService.get(`motherTongue`);
  }

  getHealthIssue() {
    return this.apiService.get(`healthIssue`);
  }

  getCaste(religionId) {
    return this.apiService.get(`caste/${religionId}`);
  }

  getSubcaste(casteId) {
    return this.apiService.get(`subcaste/${casteId}`);
  }
  getBatchByAcademic(academicYearId) {
    return this.apiService
      .get(`loadBatchByAcademic/${academicYearId}`);
  }
  // get current academic year
  getCurrentAcademicYear(): Observable<any> {
    return this.apiService.get(`loadCurrentAcademic`);
  }


  // teacher portal 

  getClasses(batchId) {
    return this.apiService.get(`loadClass/${batchId}`);
  }

  getSections(batchId) {
    return this.apiService.get(`student/loadAllSection/${batchId}`);
  }

  getSectionByClass(assignCoordinatorId) {
    return this.apiService.get(`loadSection/${assignCoordinatorId}`);
  }

}
