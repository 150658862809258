import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PreviousRouteService {

    private history = [];

    constructor(
      private router: Router
    ) {}
  
    public loadRouting(): void {
      this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe(({urlAfterRedirects}: NavigationEnd) => {
          this.history = [...this.history, urlAfterRedirects];
        });
    }
  
    public getHistory(): string[] {
      return this.history;
    }
  
    public getPreviousUrl(): string {
      return this.history[this.history.length - 2] || '/index';
    }


  /*private previousUrl: string;
  private currentUrl: string;

  constructor(private router: Router) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {        
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }    */


} 