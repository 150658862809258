<div class="header-topnav mat-elevation-z2" *ngIf="navService.visible">
  <div style="margin-left: 5px;margin-right: 10px;">
    <div class="topnav">
      <!-- App Logo -->
      <div class="topbar-branding">
        <img src="assets/images/logo.png" alt="logo" class="app-logo">
      </div>

      <ul class="menu" *ngIf="!layoutConf.isMobile">
        <li *ngFor="let item of menuItems; let i = index;">
          <div *ngIf="item.type !== 'separator'" routerLinkActive="open">
            <a style="font-weight: 500;" class="firstmenu" matRipple routerLink="/{{item.state}}"
              *ngIf="item.type === 'link'">
              <mat-icon>{{item.icon}}</mat-icon>
              {{item.name | translate}}
            </a>
            <div *ngIf="item.type === 'dropDown'">
              <label matRipple for="drop-{{i}}" class="toggle">
                <mat-icon>{{item.icon}}</mat-icon> {{item.name | translate}}
              </label>
              <a matRipple style="font-weight: 500;">
                <mat-icon>{{item.icon}}</mat-icon> {{item.name | translate}}
              </a>
              <input type="checkbox" id="drop-{{i}}" />
              <ul class="first_sub_menu">
                <li *ngFor="let itemLvL2 of item.sub; let j = index;" routerLinkActive="open">
                  <a matRipple routerLink="{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}"
                    *ngIf="itemLvL2.type !== 'dropDown'">
                    <mat-icon *ngIf="itemLvL2.icon">{{itemLvL2.icon}}</mat-icon>
                    {{itemLvL2.name | translate}}
                  </a>

                  <div *ngIf="itemLvL2.type === 'dropDown'">
                    <label matRipple for="drop-{{i}}{{j}}" class="toggle">{{itemLvL2.name | translate}}</label>
                    <a matRipple>
                      <mat-icon *ngIf="itemLvL2.icon">{{itemLvL2.icon}}</mat-icon> {{itemLvL2.name | translate}}
                    </a>
                    <input type="checkbox" id="drop-{{i}}{{j}}" />
                    <!-- Level 3 -->
                    <ul class="sec_sub_menu">
                      <li *ngFor="let itemLvL3 of itemLvL2.sub" routerLinkActive="open">
                        <a matRipple
                          routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}">
                          <mat-icon *ngIf="itemLvL3.icon">{{itemLvL3.icon}}</mat-icon>
                          {{itemLvL3.name | translate}}
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
      <span fxFlex></span>
      <!-- End Navigation -->

      <!-- Language Switcher -->
      <!-- <mat-select *ngIf="!layoutConf.isMobile" placeholder="" id="langToggle" [style.width]="'auto'" name="currentLang" [(ngModel)]="currentLang" (selectionChange)="setLang()" class="topbar-button-right">
                  <mat-option class="txt-white" *ngFor="let lang of availableLangs" [(value)]="lang.code" ngDefaultControl>
                      {{ lang.name }}</mat-option>
              </mat-select> -->

      <!-- Students Switcher -->
      <ng-container *ngIf="portalType === checkType.PARENT">
        <mat-select *ngIf="!layoutConf.isMobile" placeholder="" id="langToggle" [style.width]="'auto'"
          name="currentUser" [(ngModel)]="currentUser" (selectionChange)="setUser(currentUser)"
          class="topbar-button-right">
          <mat-option class="txt-white" *ngFor="let student of users" value="{{student.student_id}}" ngDefaultControl>
            {{ student.name }}</mat-option>
        </mat-select>
      </ng-container>

      <ng-container *ngIf="portalType === checkType.TEACHER">
        <mat-select *ngIf="!layoutConf.isMobile" placeholder="" id="langToggle" [style.width]="'auto'"
          name="currentUser" [(ngModel)]="batchId" (selectionChange)="setBatchIdForTeacher(batchId)"
          class="topbar-button-right">
          <mat-option class="txt-white" *ngFor="let batch of batches" value="{{batch.batchId}}" ngDefaultControl>
            {{ batch.batchName }}</mat-option>
        </mat-select>
      </ng-container>

      <ng-container *ngIf="portalType === checkType.HOSPITAL">
        <ul>
          <li *ngIf="router.url != '/hospital/help-desk/list'"><a style="font-weight: 500;color:#fff;"
              [class.helpdesk]="isEnable" (click)="isEnable = true"
              *ngIf="permission.hasPermission(['help_desk'],['help_list'])"
              [routerLink]="['/hospital/help-desk/list']">Help Desk</a></li>
        </ul>
        <ul>
          <li *ngIf="router.url == '/hospital/help-desk/list'"><a
              style=" background: #00000026;font-weight: 500;color:#fff;" [class.helpdesk]="isEnable"
              (click)="isEnable = true" *ngIf="permission.hasPermission(['help_desk'],['help_list'])"
              [routerLink]="['/hospital/help-desk/list']">Help Desk</a></li>
        </ul>
      </ng-container>

      <!--Message icon-->

      <!-- Notification toggle button -->
      <button mat-icon-button matTooltip="Notifications" (click)="toggleNotific()" [style.overflow]="'visible'"
        class="topbar-button-right">
        <mat-icon>notifications</mat-icon>
        <span class="notification-number mat-bg-warn">{{queueCount}}</span>
      </button>

      <!-- Top left user menu -->

      <button *ngIf="portalType == checkType.TEACHER" mat-icon-button [matMenuTriggerFor]="accountMenu"
        class="topbar-button-right mr-1 img-button">
        <img [src]="teacherProfile != '' ? teacherProfile : 'assets/images/avartar.jpg'" alt="">
      </button>

      <button *ngIf="portalType == checkType.HOSPITAL" mat-icon-button [matMenuTriggerFor]="accountMenu"
        class="topbar-button-right mr-1 img-button">
        <img [src]="'assets/images/avartar.jpg'" alt="">
      </button>

      <button *ngIf="portalType === checkType.PARENT" mat-icon-button [matMenuTriggerFor]="accountMenu"
        class="topbar-button-right mr-1 img-button">
        <img [src]="userProfile?.photo ? userProfile?.photo : 'assets/images/avartar.jpg'" alt="">
      </button>
      <!-- Original Copy
  
        <mat-menu #accountMenu="matMenu">
          <button mat-menu-item [routerLink]="['myaccount/list']">
    <mat-icon>add_shopping_cart</mat-icon>
    <span>My Product</span>
      </button>
  
          <button mat-menu-item [routerLink]="['myaccount/renewal-billing']">
    <mat-icon>payment</mat-icon>
    <span>Renewals & Billing</span>
      </button>
          <button mat-menu-item [routerLink]="['myaccount/change-password']">
    <mat-icon>lock</mat-icon>
    <span>Change Password</span>
      </button>
          <button mat-menu-item>
    <mat-icon>notifications_off</mat-icon>
    <span>Disable alerts</span>
      </button>
          <button mat-menu-item (click)="logout()">
      <mat-icon>exit_to_app</mat-icon>
      <span>Sign out</span>
      </button>
      </mat-menu> 
  
  -->

      <mat-menu #accountMenu="matMenu">

        <ng-container *ngIf="portalType === checkType.HOSPITAL && userType == 'admin'">
          <!-- <button mat-menu-item [routerLink]="['myaccount/list']">
            <mat-icon>add_shopping_cart</mat-icon>
            <span>My Product</span>
          </button>
          <button mat-menu-item [routerLink]="['myaccount/renewal-billing']">
            <mat-icon>payment</mat-icon>
            <span>Renewals & Billing</span>
          </button> -->
          <button mat-menu-item [routerLink]="['myaccount/change-password']">
            <mat-icon>lock</mat-icon>
            <span>Change Password</span>
          </button>
          <!-- <button mat-menu-item>
            <mat-icon>notifications_off</mat-icon>
            <span>Disable alerts</span>
          </button> -->
          <button mat-menu-item (click)="logout(portalType)">
            <mat-icon>exit_to_app</mat-icon>
            <span>Sign out</span>
          </button>
        </ng-container>
        <ng-container *ngIf="portalType === checkType.HOSPITAL && userType == 'user'">
          <button mat-menu-item [routerLink]="['myaccount/change-password']">
            <mat-icon>lock</mat-icon>
            <span>Change Password</span>
          </button>
          <button mat-menu-item (click)="logout(portalType)">
            <mat-icon>exit_to_app</mat-icon>
            <span>Sign out</span>
          </button>
        </ng-container>

        <ng-container *ngIf="portalType === checkType.TEACHER">
          <button mat-menu-item (click)="viewTecherMyProfile()">
            <mat-icon>home</mat-icon>
            <span>My Profile</span>
          </button>
          <button mat-menu-item [routerLink]="['my-profile/change-password']">
            <mat-icon>lock</mat-icon>
            <span>Change Password</span>
          </button>
          <button mat-menu-item>
            <mat-icon>notifications_off</mat-icon>
            <span>Disable alerts</span>
          </button>
          <button mat-menu-item (click)="logout(portalType)">
            <mat-icon>exit_to_app</mat-icon>
            <span>Sign out</span>
          </button>
        </ng-container>

        <ng-container *ngIf="portalType === checkType.PARENT">
          <!-- <button mat-menu-item [routerLink]="['myaccount/list']">
                          <mat-icon>add_shopping_cart</mat-icon>
                          <span>My Product</span>
                        </button>
                                <button mat-menu-item [routerLink]="['myaccount/renewal-billing']">
                          <mat-icon>payment</mat-icon>
                          <span>Renewals & Billing</span>
                        </button> -->
          <button mat-menu-item (click)="viewMyProfile()">
            <mat-icon>home</mat-icon>
            <span>My Profile</span>
          </button>
          <!-- <button mat-menu-item>
           
            <mat-icon>lock</mat-icon>
            <span>Change Password</span>
          </button>-->
          <button mat-menu-item>
            <mat-icon>notifications_off</mat-icon>
            <span>Disable alerts</span>
          </button>
          <button mat-menu-item (click)="logout(portalType)">
            <mat-icon>exit_to_app</mat-icon>
            <span>Sign out</span>
          </button>
        </ng-container>

        <ng-container *ngIf="portalType === checkType.CUSTOMER">
          <button mat-menu-item [routerLink]="['myaccount/list']">
            <mat-icon>add_shopping_cart</mat-icon>
            <span>My Product</span>
          </button>
          <button mat-menu-item [routerLink]="['myaccount/renewal-billing']">
            <mat-icon>payment</mat-icon>
            <span>Renewals & Billing</span>
          </button>
          <button mat-menu-item [routerLink]="['myaccount/change-password']">
            <mat-icon>lock</mat-icon>
            <span>Change Password</span>
          </button>
          <button mat-menu-item>
            <mat-icon>notifications_off</mat-icon>
            <span>Disable alerts</span>
          </button>
          <button mat-menu-item (click)="logout(portalType)">
            <mat-icon>exit_to_app</mat-icon>
            <span>Sign out</span>
          </button>
        </ng-container>

        <ng-container *ngIf="portalType === checkType.DRIVER">
          <button mat-menu-item [routerLink]="['myaccount/list']">
            <mat-icon>add_shopping_cart</mat-icon>
            <span>My Product</span>
          </button>
          <button mat-menu-item [routerLink]="['myaccount/renewal-billing']">
            <mat-icon>payment</mat-icon>
            <span>Renewals & Billing</span>
          </button>
          <button mat-menu-item [routerLink]="['myaccount/change-password']">
            <mat-icon>lock</mat-icon>
            <span>Change Password</span>
          </button>
          <button mat-menu-item>
            <mat-icon>notifications_off</mat-icon>
            <span>Disable alerts</span>
          </button>
          <button mat-menu-item (click)="logout(portalType)">
            <mat-icon>exit_to_app</mat-icon>
            <span>Sign out</span>
          </button>
        </ng-container>

      </mat-menu>

      <!-- Mobile screen menu toggle -->
      <button mat-icon-button class="mr-1" (click)="toggleSidenav()" *ngIf="layoutConf.isMobile">
        <mat-icon>menu</mat-icon>
      </button>

    </div>
  </div>
</div>