import { Injectable } from "@angular/core";
import { IBatchAPI } from "../model/batchapi.model";
import { BatchAdapter } from "./batch.adapter.service";
import { Adapter } from "app/core/adapters";
@Injectable({
  providedIn: "root",
})

export class ApiAdapter implements Adapter<IBatchAPI> {

  constructor(private adapter: BatchAdapter) {}

  adapt(response: any): IBatchAPI {
    let objList = [];
    response.data.forEach((row) => {
      objList.push(this.adapter.adapt(row));
    });

    return {
      keyword: response.keyword,
      message: response.message,
      batches: objList,
      pageSize: response.count,
      page: response.page,
    };
  }
}
