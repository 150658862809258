import { Injectable } from "@angular/core";
import { routeModules } from '../models/route_path';
import { ReplaySubject } from "rxjs";
import { Router } from "@angular/router";

@Injectable({
    providedIn: "root"
})

export class GenerateRouteService {


    constructor(private route: Router) {

    }

    public queueCount = new ReplaySubject<any>(0);

    /** queue count */

    setQueueCount(property: any, storeProp: boolean = false) {
        if (storeProp) {
            localStorage.setItem('queueCount', JSON.stringify(property));
        }
        this.queueCount.next(property);
    }

    getQueueCount() {
        return this.queueCount;
    }

    generateRoutePath(portalName, page, idObj) {
        let splitPath = page.split('_');
        let givenPathName = splitPath.pop();
        let givenModule = splitPath.join('-');
        let getPortalModules = routeModules[portalName];
        let { actualModule, path } = this.filterByModule(getPortalModules, givenModule);
        let pathName = this.filterByPath(path, givenPathName, idObj);
        portalName = portalName == 'teacher' ? '/' + portalName : portalName;
        return `${portalName}/${actualModule}/${pathName}`;
    }

    filterByModule(getPortalModules, moduleName) {
        return getPortalModules.find(el => el.givenModule == moduleName);
    }

    filterByPath(getPath, pathName, idObj) {
        let filteredPath = getPath.find(el => el.givenPath == pathName);
        return this.filterQueryParams(filteredPath, idObj);
    }

    filterQueryParams(params, idObj) {
        let pathQuery;
        if (params.isMultipleIdRequired == true) {
            let length = params.givenMulipleIds.length;
            if (length > 1) {
                pathQuery = params.givenMulipleIds.reduce((prev, curr) => String(idObj[prev]) + '/' + String(idObj[curr]))
            } else {
                pathQuery = idObj[params.givenMulipleIds[0]];
            }
        } else if (params.isSingleIdRequired == true) {
            pathQuery = params.actualPath + '/' + idObj[params.givensingleIdName];
        } else {
            pathQuery = params.actualPath;
        }
        return pathQuery;
    }

    navigateRouteForParent(portalName, page, idObj) {
        if (page == 'leave_request_view') {
            this.route.navigate(["/parent/leave-request/view/", idObj?.leave_request_id])
        } else if (page == 'stu_leave_req') {
            this.route.navigate(["/parent/leave-request/view/", idObj?.leave_request_id])
        } else if (page == 'homework_view') {
            this.route.navigate(["/parent/home-work/list"]);
        } else if (page == 'homework_list') {
            this.route.navigate(["/parent/home-work/list"]);
        } else if (page == 'report_card') {
            this.route.navigate(["/parent/report-card/list"]);
        } else if (page == 'holiday_view') {
            this.route.navigate(["/parent/meeting-events"]);
        } else if (page == 'event_view') {
            this.route.navigate(["/parent/meeting-events"]);
        }
    }

    navigateRouteForTeacher(portalName, page, idObj) {
        if (page == 'leave_request_view') {
            this.route.navigate(["/teacher/leave-request/view/", idObj?.leave_request_id])
        } else if (page == 'homework_view') {
            this.route.navigate(["/teacher/home-work/view/", idObj?.homework_id]);
        } else if (page == 'homework_assignment') {
            this.route.navigate(["/teacher/home-work/assignment/view/", idObj?.homework_id]);
        } else if (page == 'event_view') {
            this.route.navigate(["/teacher/events-meetings/list"]);
        }
    }

    navigateRouteForSchool(portalName, page, idObj) {
        if (page == 'emp_leave_req') {
            this.route.navigate(["/hospital/employee/leave-request/view/", idObj?.leave_request_id])
        } else if (page == 'stu_leave_req') {
            this.route.navigate(["/hospital/student/leave-request/view/", idObj?.leave_request_id])
        } else if (page == 'homework_view') {
            this.route.navigate(["/hospital/home-work/view/", idObj?.homework_id]);
        } else if (page == 'homework_assignment') {
            this.route.navigate(["/hospital/home-work/assignment/view/", idObj?.homework_id]);
        } else if (page == 'ticket_view') {
            this.route.navigate(["/hospital/help-desk/ticketdetail/", idObj?.ticket_id]);
        } else if (page == 'holiday_view') {
            this.route.navigate(["/hospital/holiday-settings/list"]);
        } else if (page == 'renew_billing') {
            this.route.navigate(["/hospital/myaccount/renewal-billing"]);
        } else if (page == 'vehicle_maintenance_view') {
            this.route.navigate([`/hospital/transport/vehicle-maintenance/view/${idObj?.trans_vehicle_manage_id}/0`]);
        } else if (page == 'event_view') {
            this.route.navigate(["/hospital/events-meetings/list"]);
        } else if (page == 'daily_schedule_view') {
            this.route.navigate(["/hospital/daily-schedule/view/" , idObj?.daily_schedule_id]);
        }
    }
}