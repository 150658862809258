<div class="patient-view-details">

  <h3><strong>Patient Details</strong><span (click)="discard()">
      <mat-icon>close</mat-icon>
    </span></h3>
  <div class="patients">
    <div class="patient-info" *ngFor="let patient of commonPayload">
      <div fxFlex="100" fxFlex.gt-xs="10" class="profile-img">
        <img src="{{getPatientPhoto(patient?.id)}}" width="70" height="70">
      </div>
      <div fxFlex="100" fxFlex.gt-xs="90">
        <div class="patient-details">
          <table>
            <tr>
              <td class="heading"><strong>Name</strong></td>
              <td class="value">{{patient?.name}}</td>
            </tr>
            <tr>
              <td class="heading"><strong>Gender</strong></td>
              <td class="value">{{patient?.gender}}</td>
            </tr>
            <!-- <tr>
              <td class="heading"><strong>Father Name</strong></td>
              <td class="value">{{patient?.fathername}}</td>
            </tr> -->
            <tr>
              <td class="heading"><strong>Age</strong></td>
              <td class="value">{{patient?.age}}</td>
            </tr>
            <tr>
              <td class="heading"><strong>Address</strong></td>
              <td class="value">{{patient?.address1}} {{patient?.address2}}, {{patient?.state}}, {{patient?.country}},
                {{patient?.city}}-{{patient?.zipcode}}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <!-- <div class="patient-info">
      <div fxFlex="100" fxFlex.gt-xs="10" class="profile-img">
        <img src="assets/images/avartar.jpg" width="70" height="70">
      </div>
      <div fxFlex="100" fxFlex.gt-xs="90">
        <div class="patient-details">
          <table>
            <tr>
              <td class="heading"><strong>Name</strong></td>
              <td class="value">N.Hari hara pandiyan</td>
            </tr>
            <tr>
              <td class="heading"><strong>Gender</strong></td>
              <td class="value">Male</td>
            </tr>
            <tr>
              <td class="heading"><strong>Father Name</strong></td>
              <td class="value">M.Saravanan</td>
            </tr>
            <tr>
              <td class="heading"><strong>Age</strong></td>
              <td class="value">23</td>
            </tr>
            <tr>
              <td class="heading"><strong>Address</strong></td>
              <td class="value">33/5 Sakthi vellammal Street, 1st floor,S.S.Colony, By pass road, Madurai-10.</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="patient-info">
      <div fxFlex="100" fxFlex.gt-xs="10" class="profile-img">
        <img src="assets/images/avartar.jpg" width="70" height="70">
      </div>
      <div fxFlex="100" fxFlex.gt-xs="90">
        <div class="patient-details">
          <table>
            <tr>
              <td class="heading"><strong>Name</strong></td>
              <td class="value">N.Hari hara pandiyan</td>
            </tr>
            <tr>
              <td class="heading"><strong>Gender</strong></td>
              <td class="value">Male</td>
            </tr>
            <tr>
              <td class="heading"><strong>Father Name</strong></td>
              <td class="value">M.Saravanan</td>
            </tr>
            <tr>
              <td class="heading"><strong>Age</strong></td>
              <td class="value">23</td>
            </tr>
            <tr>
              <td class="heading"><strong>Address</strong></td>
              <td class="value">33/5 Sakthi vellammal Street, 1st floor,S.S.Colony, By pass road, Madurai-10.</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="patient-info">
      <div fxFlex="100" fxFlex.gt-xs="10" class="profile-img">
        <img src="assets/images/avartar.jpg" width="70" height="70">
      </div>
      <div fxFlex="100" fxFlex.gt-xs="90">
        <div class="patient-details">
          <table>
            <tr>
              <td class="heading"><strong>Name</strong></td>
              <td class="value">N.Hari hara pandiyan</td>
            </tr>
            <tr>
              <td class="heading"><strong>Gender</strong></td>
              <td class="value">Male</td>
            </tr>
            <tr>
              <td class="heading"><strong>Father Name</strong></td>
              <td class="value">M.Saravanan</td>
            </tr>
            <tr>
              <td class="heading"><strong>Age</strong></td>
              <td class="value">23</td>
            </tr>
            <tr>
              <td class="heading"><strong>Address</strong></td>
              <td class="value">33/5 Sakthi vellammal Street, 1st floor,S.S.Colony, By pass road, Madurai-10.</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="patient-info">
      <div fxFlex="100" fxFlex.gt-xs="10" class="profile-img">
        <img src="assets/images/avartar.jpg" width="70" height="70">
      </div>
      <div fxFlex="100" fxFlex.gt-xs="90">
        <div class="patient-details">
          <table>
            <tr>
              <td class="heading"><strong>Name</strong></td>
              <td class="value">N.Hari hara pandiyan</td>
            </tr>
            <tr>
              <td class="heading"><strong>Gender</strong></td>
              <td class="value">Male</td>
            </tr>
            <tr>
              <td class="heading"><strong>Father Name</strong></td>
              <td class="value">M.Saravanan</td>
            </tr>
            <tr>
              <td class="heading"><strong>Age</strong></td>
              <td class="value">23</td>
            </tr>
            <tr>
              <td class="heading"><strong>Address</strong></td>
              <td class="value">33/5 Sakthi vellammal Street, 1st floor,S.S.Colony, By pass road, Madurai-10.</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="patient-info">
      <div fxFlex="100" fxFlex.gt-xs="10" class="profile-img">
        <img src="assets/images/avartar.jpg" width="70" height="70">
      </div>
      <div fxFlex="100" fxFlex.gt-xs="90">
        <div class="patient-details">
          <table>
            <tr>
              <td class="heading"><strong>Name</strong></td>
              <td class="value">N.Hari hara pandiyan</td>
            </tr>
            <tr>
              <td class="heading"><strong>Gender</strong></td>
              <td class="value">Male</td>
            </tr>
            <tr>
              <td class="heading"><strong>Father Name</strong></td>
              <td class="value">M.Saravanan</td>
            </tr>
            <tr>
              <td class="heading"><strong>Age</strong></td>
              <td class="value">23</td>
            </tr>
            <tr>
              <td class="heading"><strong>Address</strong></td>
              <td class="value">33/5 Sakthi vellammal Street, 1st floor,S.S.Colony, By pass road, Madurai-10.</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="patient-info">
      <div fxFlex="100" fxFlex.gt-xs="10" class="profile-img">
        <img src="assets/images/avartar.jpg" width="70" height="70">
      </div>
      <div fxFlex="100" fxFlex.gt-xs="90">
        <div class="patient-details">
          <table>
            <tr>
              <td class="heading"><strong>Name</strong></td>
              <td class="value">N.Hari hara pandiyan</td>
            </tr>
            <tr>
              <td class="heading"><strong>Gender</strong></td>
              <td class="value">Male</td>
            </tr>
            <tr>
              <td class="heading"><strong>Father Name</strong></td>
              <td class="value">M.Saravanan</td>
            </tr>
            <tr>
              <td class="heading"><strong>Age</strong></td>
              <td class="value">23</td>
            </tr>
            <tr>
              <td class="heading"><strong>Address</strong></td>
              <td class="value">33/5 Sakthi vellammal Street, 1st floor,S.S.Colony, By pass road, Madurai-10.</td>
            </tr>
          </table>
        </div>
      </div>
    </div> -->
  </div>
</div>