import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiAdapter } from './api.adapter.service';
import { IBatchAPI } from '../model/batchapi.model';
import { ApiService } from 'app/core/services/api.service';
import { ClassApiAdapter } from './classapi.adapter.service';
import { IClassesAPI } from '../model/classapi.model';

@Injectable({
  providedIn: 'root'
})

export class BatchService {

  // public recordAddedSubject = new BehaviorSubject<Role[]>([{}] as Role[]);
  // public recordUpdatedSubject = new BehaviorSubject<Role[]>([{}] as Role[]);
  // public userId : any = 0;

  constructor(
    private adapter: ApiAdapter, 
    private apiService: ApiService,
    private classAdapter : ClassApiAdapter) {  }




  // create new record
  create(formData) : Observable<IBatchAPI>{
    return this.apiService.post(`batch/create`, formData).pipe(map(response => this.adapter.adapt(response)));
  }

  // update form data
  update(formData) : Observable<IBatchAPI>{
    return this.apiService.post(`batch/update`, formData).pipe(map(response => this.adapter.adapt(response)));
  }

  // active & delete & deactive 

  status(body, status) {
    if (status != 2) {
      return this.apiService.post(`batch/status`, body);
    }else  return this.apiService.post(`batch/delete`, body);

  }

  // list with filtering pagination
  getListForBatch(paginationDTO): Observable<IBatchAPI> {
    let params = new HttpParams();
    for (let key in paginationDTO) {
      params = params.set(key, paginationDTO[key]);
    }
    return this.apiService.get(`batch`, params).pipe(map(response => this.adapter.adapt(response)));
  }
  getList(): Observable<IBatchAPI> {
    let params = new HttpParams();
    return this.apiService.get(`batch`, params).pipe(map(response => this.adapter.adapt(response)));
  }



  // get one batch
  getOne(id): Observable<IBatchAPI> {
    return this.apiService
      .get(`batch/${id}`)
      .pipe(map((response) => this.adapter.adapt(response)));
  }


//get classes

getClasses(academicId :number, id :number ) :Observable<IClassesAPI>{
  return this.apiService.get(`batch/loadClass/${academicId}/${id}`).pipe(map(response => this.classAdapter.adapt(response)));
}

getListByAcademic(academicId): Observable<IBatchAPI> {
  return this.apiService.get(`loadBatchByAcademic/${academicId}`).pipe(map(response => this.adapter.adapt(response)));
}
  // getRecordAddedSub(): Observable<Role[]> {
  //   return this.recordAddedSubject.asObservable();
  // } 
  // getRecordUpdatedSub(): Observable<Role[]> {
  //   return this.recordUpdatedSubject.asObservable();
  // }


}
