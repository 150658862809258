import { Component, OnInit } from '@angular/core';
import {  Subscription, ReplaySubject } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'app/core/services/user.service';
import { ConfigService } from 'app/core/services/config.service';
import { environment } from 'environments/environment';


@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class SosNotificationComponent implements OnInit {

  payLoad = [];
  empPayload = [];
  stuPayload = [];
  filterEmployee = [];
  filterStudent = [];
  sosSub: Subscription;
  commonPayload = [];

  audio = new Audio();
  customerId
  hospitalId
  constructor(
    private userServ: UserService,
    public dialogRef: MatDialogRef<SosNotificationComponent>,
    private configServ: ConfigService,
  ) {

    this.configServ.init();
    this.customerId = this.configServ.customerId;
    this.hospitalId = this.configServ.branchId;


    this.playAudio();
  }

  playAudio() {
    this.audio.src =
      "assets/music/nuclear_power_station_meltdown_alarm.mp3";
    this.audio.load();
    this.audio.loop = true;
    this.audio.play();
  }

  stopAudio() {
    this.audio.pause();
    this.audio.currentTime = 0;
  }


  ngOnInit() {
    this.getSosNotifies();
  }


  getSosNotifies() {
    this.sosSub = this.userServ.arrayOfSosSub
      .subscribe(res => {
        if (res) {
          this.payLoad.unshift(res);
          console.log(res, "response")
          this.empPayload = this.payLoad.filter((emp) => emp.personType == 'Employee');
          this.FilterEmployeelist();
          this.commonPayload = [...this.filterEmployee];
          this.commonPayload.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
        }
      });
  }


  FilterEmployeelist() {
    this.filterEmployee = this.empPayload.reduce(function (previous, current) {
      var object = previous.filter(object => object.employeeId === current.employeeId);
      if (object.length == 0) {
        previous.push(current);
      }
      return previous;
    }, []);

  }


  ngOnDestroy() {
    this.payLoad = [];
    this.empPayload = [];
    this.stuPayload = [];
    this.filterEmployee = [];
    this.filterStudent = [];
    this.commonPayload = [];
    this.sosSub.unsubscribe();
    this.userServ.arrayOfSosSub = new ReplaySubject();
  }

  getEmployeePhoto(id) {
    return `${environment.imageUrl}public/employee/employee-${this.customerId}-${this.hospitalId}-${id}.png`
  }



  discard() {
    this.dialogRef.close();
    this.stopAudio();
  }

}