import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ParentService } from './parent.service';
import { take, map } from 'rxjs/operators';
import { CurrentUserService } from './current-user.service';

@Injectable({
  providedIn: 'root'
})
export class ParentAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private currentUserService: CurrentUserService,
    private parentService: ParentService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    return this.parentService.isLoggedIn.pipe(take(1),
      map((isLoggedIn: boolean) => {
        let checkOTP = localStorage.getItem('ISOTPVERIFIED') ? true : false;
        let canLogin = isLoggedIn && checkOTP;
        if (!canLogin) {
          // this.router.navigate(['/']); 
          this.parentService.logout();
          return false;
        }
        return true;
      })
    );
  }
}