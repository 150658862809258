import { IMenuItem } from "./navigation.model";

export let customerMenuList: IMenuItem[] = [
  {
    module: [],
    name: "Overview",
    type: "link",
    tooltip: "Overview",
    state: "overview",
    badges: [{ color: "primary", value: "1" }],
  },

  {
    module: [],
    name: "Custmer",
    type: "link",
    tooltip: "Overview",
    state: "overview",
    badges: [{ color: "primary", value: "1" }],
  },
];

export let teacherMenuList: IMenuItem[] = [
  {
    module: [],
    name: "Overview",
    type: "link",
    tooltip: "Overview",
    state: "/teacher/dashboard",
    badges: [{ color: "primary", value: "1" }],
  },

  {
    module: ["Attendance"],
    name: "Attendance",
    type: "dropDown",
    tooltip: "Attendance",
    state: "",
    sub: [
      {
        name: "General Attendance",
        module: ["general_attendance"],
        state: "/teacher/attentance/generalattendance",
      },
      {
        name: "Time Atttendance",
        module: ["time_attendance"],
        state: "/teacher/attentance/timeattendance",
      },
    ],
  },
  {
    module: [
      "student_registration",
      "stu_manual_attendance",
      "stu_leave_req",
    ],
    name: "Students",
    type: "dropDown",
    tooltip: "Students",
    state: "",
    sub: [
      {
        name: "Students List",
        module: ["student_registration"],
        state: "/teacher/student/list",
      },
      {
        name: "Attendance",
        type: "dropDown",
        module: ["stu_manual_attendance"],
        state: "",
        sub: [
          {
            name: "Manual Attendance",
            module: ["stu_manual_attendance"],
            state: "/teacher/student/manualattendance-search"
          },
        ],
      },
      {
        name: "Leave Request",
        module: ["stu_leave_req"],
        state: "/teacher/student/leave-request/list",
      },
    ],
  },
  {
    module: ["emp_leave_req"],
    name: "Leave Request",
    type: "link",
    tooltip: "Leave Request",
    state: "/teacher/leave-request/list",
  },
  {
    module: [
      "homework",
      "online_exam",
      "exam_planner",
      "exam_time_table",
      "events_meetings",
      "mark_entry",
      "teacher_time_table",
      "calendar"
    ],
    name: "Academic",
    type: "dropDown",
    tooltip: "examination",
    state: "",
    sub: [
      {
        name: "Home Work",
        module: ["homework"],
        state: "/teacher/home-work/list",
      },
      {
        name: "Online Exam",
        module: ["online_exam"],
        state: "/teacher/online-exam/list",
      },
      {
        name: "Examination",
        type: "dropDown",
        state: "",
        module: ["exam_planner", "exam_time_table", "mark_entry"],
        sub: [
          {
            name: "Exam Planner",
            module: ["exam_planner"],
            state: "/teacher/exam-planner",
          },
          {
            name: "Exam Time Table",
            module: ["exam_time_table"],
            state: "/teacher/exam-time-table",
          },
          {
            name: "Exam Mark Entry",
            module: ["mark_entry"],
            state: "/teacher/mark-entry/select-section",
          },
        ],
      },
      {
        name: "Teacher Time Table",
        type: "link",
        module: ["teacher_time_table"],
        state: "/teacher/teacher-timetable/subject",
      },
      {
        name: "Events/Meetings",
        module: ["event"],
        state: "/teacher/events-meetings/list",
      },
      {
        name: "Calendar",
        module: ["calendar"],
        state: "/teacher/calendar/list",
      },
    ],
  },
  {
    module: ["gallery_category", "gallery"],
    name: "Gallery",
    type: "dropDown",
    tooltip: "Gallery",
    state: "",
    sub: [
      {
        name: "Category",
        module: ["gallery_category"],
        state: "/teacher/category/list",
      },
      {
        name: "Manage Gallery",
        module: ["gallery"],
        state: "/teacher/gallery/list",
      },
    ],
  },
  {
    module: ["employee_report", "student_report", "report_card"],
    name: "Reports",
    type: "dropDown",
    tooltip: "Employees",
    state: "",
    sub: [
      {
        module: ["student_report", "report_card"],
        name: "Attendance",
        type: "dropDown",
        state: "",
        sub: [
          {
            module: ["employee_report"],
            name: "Employee",
            state: "/teacher/reports/employee-attendance"
          },
          {
            module: ["student_report"],
            name: "Student",
            state: "/teacher/reports/student-attendance"
          },
        ]
      },
      {
        module: ["report_card"],
        name: "Report Card",
        type: "link",
        state: "/teacher/reports/report-card/search-section",
      },
    ]
  },
  {
    module: [""],
    name: "To Do List",
    type: "link",
    tooltip: "To Do List",
    state: "/teacher/to-do-list/list",
  },
];

export let parentMenuList: IMenuItem[] = [
  {
    module: ["dashboard"],
    name: "Overview",
    type: "link",
    tooltip: "Overview",
    state: "parent/dashboard",
    badges: [{ color: "primary", value: "1" }],
  },
  {
    module: ["attendance"],
    name: "Attendance",
    type: "link",
    tooltip: "Attendance",
    state: "parent/attendance/view"
  },
  {
    module: [
      "home-work",
      "online-exam",
      "calendar"
    ],
    name: "Academic",
    type: "dropDown",
    tooltip: "examination",
    state: "",
    sub: [
      {
        name: "Home Work",
        module: ["home-work"],
        state: "/parent/home-work/list",
      },
      {
        name: "Online Exam",
        module: ["online-exam"],
        state: "/parent/online-exam/list",
      },
      {
        name: "Calendar",
        module: ["calendar"],
        state: "/parent/calendar/list",
      },
    ]
  },

  // {
  //   module: ["home-work"],
  //   name: "Home Work",
  //   type: "link",
  //   tooltip: "Home Work",
  //   state: "parent/home-work/list"
  // },
  // {
  //   module: ["online-exam"],
  //   name: "Online Exam",
  //   type: "link",
  //   tooltip: "Online Exam",
  //   state: "parent/online-exam/list"
  // },
  {
    module: ["leave-request"],
    name: "Leave",
    type: "link",
    tooltip: "Leave",
    state: "parent/leave-request/list"
  },
  {
    module: ["transport"],
    name: "Transport",
    type: "link",
    tooltip: "Transport",
    state: "parent/transport/view"
  },
  {
    module: ["fees"],
    name: "Fees",
    type: "link",
    tooltip: "Fees",
    state: "parent/fees/fees-type"
  },
  // {
  //   module: ["overview"],
  //   name: "Fees",
  //   type: "link",
  //   tooltip: "Fees",
  //   state: "parent/fees/view"
  // },
  {
    module: ["meeting-events"],
    name: "Meeting & Events",
    type: "link",
    tooltip: "Meeting & Events",
    state: "parent/meeting-events"
  },
  {
    module: ["gallery"],
    name: "Gallery",
    type: "link",
    tooltip: "Gallery",
    state: "parent/gallery/list"
  },
  {
    module: ["class-timetable"],
    name: "Time Table",
    type: "link",
    tooltip: "Time Table",
    state: "parent/class-timetable/view"
  },
  {
    module: ["report-card"],
    name: "Report Card",
    type: "link",
    tooltip: "Report Card",
    state: "parent/report-card/list"
  },

];

//hosp
export let adminMenuList: IMenuItem[] = [
  {
    module: ["overview"],
    name: "Overview",
    type: "link",
    tooltip: "Overview",
    state: "/hospital/dashboard",
    badges: [{ color: "primary", value: "1" }],
  },
  {
    module: [
      "employee_registration",
      "emp_manual_attendance",
      "emp_general_attendance",
      "emp_time_attendance",
      "emp_leave_req"],
    name: "Employees",
    type: "dropDown",
    tooltip: "Employees",
    state: "",
    sub: [
      {
        name: "Employee Registration",
        module: ["employee_registration"],
        state: "/hospital/employee/list",
      },
      {
        name: "Leave Request",
        module: ["emp_leave_req"],
        state: "/hospital/employee/leave-request/list",
      },
      {
        name: "Attendance",
        type: "dropDown",
        module: [
          "emp_manual_attendance",
          "emp_general_attendance",
          "emp_time_attendance"
        ],
        state: "",
        sub: [
          {
            name: "Manual Attendance",
            module: ["emp_manual_attendance"],
            state: "/hospital/employee/manualattendance-search"
          },
          {
            name: "General Attendance",
            module: ["emp_general_attendance"],
            state: "/hospital/employee/attendance",
          },
          {
            name: "Time and Attendance",
            module: ["emp_time_attendance"],
            state: "/hospital/employee/time-attendance",
          },
        ],
      },

    ],
  },
  // {
  //   module: ["patient"],
  //   name: "Patient",
  //   type: "link",
  //   tooltip: "Patient",
  //   state: "/hospital/patient/list"
  // },
  // {
  //   module: ["patient"],
  //   name: "Bed Status",
  //   type: "link",
  //   tooltip: "Patient",
  //   state: "/hospital/bed-status/view"
  // },
  {
    module: ["assets"],
    name: "Assets",
    type: "link",
    tooltip: "Assets",
    state: "/hospital/assets/list"
  },
  {
    module: ["visitors"],
    name: "Visitors",
    type: "link",
    tooltip: "Visitors",
    state: "/hospital/visitors/list",
  },
  {
    module: ["employee_report", "no_movement", "not_allowed"],
    name: "Reports",
    type: "dropDown",
    tooltip: "Employees",
    state: "",
    sub: [
      {
        module: ["report_card"],
        name: "Attendance",
        type: "dropDown",
        state: "",
        sub: [
          {
            module: ["employee_report"],
            name: "Employee",
            state: "/hospital/reports/employee-attendance"
          },
        ]
      },
      {
        module: ["no_movement"],
        name: "No Movement",
        type: "dropDown",
        state: "",
        sub: [
          { name: "Employee", module: ["no_movement"], state: "/hospital/reports/employeeNoMove" },
          { name: "Visitor", module: ["no_movement"], state: "/hospital/reports/visitorNoMove" }
        ]
      },
      {
        module: ["not_allowed"],
        name: "Restricted Area",
        type: "dropDown",
        state: "",
        sub: [
          { name: "Employee", module: ["not_allowed"], state: "/hospital/reports/not-allowed/employee" },
          { name: "Visitor", module: ["not_allowed"], state: "/hospital/reports/not-allowed/visitor" }
        ]
      },
      {
        module: ["asset-report"],
        name: "TimeLine Report",
        type: "dropDown",
        state: "",
        sub: [
          { name: "Asset", module: ["asset-report"], state: "/hospital/reports/asset-report" },
   
        ]
      },
      // {
      //   module: ["performance"],
      //   name: "Performance",
      //   type: "dropDown",
      //   state: "",
      //   sub: [
      //     { name: "Overall", module: ["performance"], state: "/hospital/reports/performance/overall" },
      //     { name: "Patient", module: ["performance"], state: "/hospital/reports/performance/patient" }
      //   ]
      // },
      // {
      //   module: ["patient_report"],
      //   name: "Patient Report",
      //   type: "link",
      //   state: "/hospital/reports/patient_report"
      // },
    ]
  },
  // {
  //   module: ["lifecycle"],
  //   name: "Life-Cycle",
  //   type: "link",
  //   tooltip: "Life-Cycle",
  //   state: "/hospital/lifecycle/list"
  // },
  // {
  //   module: ["maintanance"],
  //   name: "Maintance",
  //   type: "link",
  //   tooltip: "Maintanance",
  //   state: "/hospital/maintanance/list"
  // },
  {
    module: [
      "theme_setting",
      "shift_time_table",
      "emp_department",
      "emp_position",
      "emp_qulaification",
      "speciality",
      "holiday_setting",
      "infastructure",
      'emp_attendance_setting',
      // 'stu_attendance_setting',
      // "holiday_setting",
      "layout_category",
      "layout_position",
      "rule_engine",
      // "threshold_response_time",
      "web_setting"
    ],
    name: "Configuration",
    type: "dropDown",
    tooltip: "Configuration",
    state: "",
    sub: [
      {
        module: ["shift_time_table", "emp_department", "emp_position", "emp_qualification", "speciality", "holiday_setting", "threshold_response_time"],
        name: "Employee Settings",
        type: "dropDown",
        state: "",
        sub: [
          // {
          //   name: "Shift",
          //   module: ["shift_time_table"],
          //   state: "hospital/shift-time/list",
          // },
          {
            name: "Department",
            module: ["emp_department"],
            state: "hospital/department/list",
          },
          {
            name: "Job Position",
            module: ["emp_position"],
            state: "hospital/position/list",
          },
          {
            name: "Qualification",
            module: ["emp_qualification"],
            state: "hospital/qualification/list",
          },
          {
            name: "Speciality",
            module: ["speciality"],
            state: "hospital/speciality/list",
          },
          { name: "Holiday Settings", module: ['holiday_setting'], state: "hospital/holiday-settings/list" },
          {
            name: "Threshold Response Time",
            module: ["threshold_response_time"],
            state: "hospital/threshold-response/update",
          },
        ],
      },
      {
        name: "Attendance Settings",
        module: ['shift_details', 'shift_assign'],
        type: "dropDown",
        state: "",
        sub: [
          {
            name: "Shift Details",
            module: ['shift_details'],
            state: "hospital/attendance-settings/shift-details/list",
          },
          {
            name: "Shift Assign",
            module: ['shift_assign'],
            state: "hospital/attendance-settings/shift-assign/list",
          },
        ],
      },
      // {
      //   module: ["treatment", "disease"],
      //   name: "Treatment Settings",
      //   type: "dropDown",
      //   state: "",
      //   sub: [         
      //     {
      //       name: "Disease",
      //       module: ["disease"],
      //       state: "hospital/disease/list",
      //     },
      //     {
      //       name: "Treatment",
      //       module: ["treatment"],
      //       state: "hospital/treatment/list",
      //     },
      //   ],
      // },
      {
        module: ["layout_category", "layout_position", "rule_engine"],
        name: "Layout Settings",
        type: "dropDown",
        state: "",
        sub: [
          {
            name: "Layout Category",
            module: ["layout_category"],
            state: "hospital/layout/category/list",
          },
          {
            name: "Layout Position",
            module: ["layout_position"],
            state: "hospital/layout/position/list",
          },
          {
            name: "Room / Ward",
            module: ["layout_position"],
            state: "hospital/layout/room-ward/list",
          },
          {
            name: "Layout Rule Engine",
            module: ["rule_engine"],
            state: "hospital/layout/rule-engine/list",
          },
        ],
      },
      // {
      //   name: "Nursing Station",
      //   module: ["nursing-station"],
      //   state: "hospital/nursing-station/list",
      // },
      {
        name: "Infrastructure",
        module: ["infrastructure"],
        state: "hospital/layout/preview",
      },
      // {
      //   name: "Bed Settings",
      //   module: ["bed_setting"],
      //   state: "hospital/bed-settings/list",
      // },
      {
        name: "Theme Settings",
        module: ["theme_setting"],
        state: "hospital/theme-setting",
      },
      {
        name: "Web Settings",
        module: ["web_setting"],
        state: "hospital/websettings/update",
      },
    ],
  },
];
