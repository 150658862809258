import { Routes } from "@angular/router";
import { AdminLayoutComponent } from "./shared/components/layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./shared/components/layouts/auth-layout/auth-layout.component";
import { AuthGuard } from "./core/services/auth-guard.service";
import { ParentAuthGuard } from "./core/services/parent-auth-guard.service";
import { TeacherAuthGuard } from "./core/services/teacher-auth-guard.service";
export const rootRouterConfig: Routes = [
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./modules/hospital/sessions/sessions.module").then(
            (m) => m.SessionsModule
          ),
        data: { title: "Hospital" },
      },

    ],
  },
  {
    path: "hospital",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("./modules/hospital/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
        data: { title: "Dashboard", breadcrumb: "Dashboard" },
      },
      {
        path: "help-desk",
        loadChildren: () =>
          import("./modules/hospital/help-desk/support.module").then(
            (m) => m.AppSupportModule
          ),
        data: { title: "Help Desk", breadcrumb: "Help Desk" },
      },
      {
        path: 'notifications',
        loadChildren: () => import('./modules/hospital/notifications/notifications.module').then(m => m.HospitalNotificationsModule),
        data: { title: "Notifications", breadcrumb: "Notifications" }
      },
      {
        path: 'layout',
        loadChildren: () => import('./modules/hospital/layout/layout.module').then(m => m.AppLayoutModule),
        data: { title: "Layout Settings", breadcrumb: "Layout Settings" }
      },
      {
        path: "theme-setting",
        loadChildren: () =>
          import("./modules/hospital/themesetting/themesetting.module").then(
            (m) => m.AppThemeModule
          ),
        data: { title: "Theme Setting", breadcrumb: "Theme Setting" },
      },
      {
        path: "holiday-settings",
        loadChildren: () =>
          import(
            "./modules/hospital/holiday-settings/holiday-settings.module"
          ).then((m) => m.AppHolidaysettingsModule),
        data: { title: "Holiday Settings", breadcrumb: "Employee Settings / Holiday Settings" },
      },
      {
        path: 'tracking',
        loadChildren: () => import('./modules/hospital/tracking/tracking.module').then(m => m.ApptrackingModule),
        data: { title: "Live Tracking", breadcrumb: "Live Tracking" }
      },
      {
        path: "department",
        loadChildren: () =>
          import("./modules/hospital/department/department.module").then(
            (m) => m.AppDepartmentModule
          ),
        data: { title: "Department", breadcrumb: "Employee Settings / Department" },
      },
      {
        path: "position",
        loadChildren: () =>
          import("./modules/hospital/position/position.module").then(
            (m) => m.AppPositionModule
          ),
        data: { title: "Job Position", breadcrumb: "Employee Settings / Job Position" },
      },
      {
        path: "qualification",
        loadChildren: () =>
          import("./modules/hospital/qualification/qualification.module").then(
            (m) => m.AppQualificationModule
          ),
        data: { title: "Qualification", breadcrumb: "Employee Settings / Qualification" },
      },
      {
        path: "shift-time",
        loadChildren: () =>
          import("./modules/hospital/shift-time/shift-time.module").then(
            (m) => m.AppShiftTimeModule
          ),
        data: { title: "Shift Time", breadcrumb: "Shift Time" },
      },
      {
        path: "myaccount",
        loadChildren: () =>
          import("./modules/hospital/myaccount/myaccount.module").then(
            (m) => m.AppAccountModule
          ),
        data: { title: "My Account", breadcrumb: "My Account" },
      },
      {
        path: "speciality",
        loadChildren: () =>
          import("./modules/hospital/speciality/speciality.module").then(
            (m) => m.AppSpecialityModule
          ),
        data: { title: "Speciality", breadcrumb: "Employee Settings / Speciality" },
      },
      {
        path: "websettings",
        loadChildren: () =>
          import("./modules/hospital/websettings/websettings.module").then(
            (m) => m.AppWebsettingsModule
          ),
        data: { title: "Web-Settings", breadcrumb: "Web-Settings" },
      },
      {
        path: "bed-settings",
        loadChildren: () =>
          import("./modules/hospital/bed-settings/bed-settings.module").then(
            (m) => m.AppBedSettingsModule
          ),
        data: { title: "Bed Settings", breadcrumb: "Bed Settings" },
      },
      {
        path: "employee",
        loadChildren: () =>
          import("./modules/hospital/employee/employee.module").then(
            (m) => m.AppEmployeeModule
          ),
        data: { title: "Employee", breadcrumb: "Employee" },
      },
      {
        path: "patient",
        loadChildren: () =>
          import("./modules/hospital/patient/patient.module").then(
            (m) => m.AppPatientModule
          ),
        data: { title: "Patient", breadcrumb: "Patient" },
      },
      {
        path: "assets",
        loadChildren: () =>
          import("./modules/hospital/assets/assets.module").then(
            (m) => m.AppAssetsModule
          ),
        data: { title: "Assets", breadcrumb: "Assets" },
      },
      {
        path: "bed-status",
        loadChildren: () =>
          import("./modules/hospital/bed-status/bed-status.module").then(
            (m) => m.AppBedstatusModule
          ),
        data: { title: "Bed Status", breadcrumb: "Bed Status" },
      },
      {
        path: "visitors",
        loadChildren: () =>
          import("./modules/hospital/visitors/visitors.module").then(
            (m) => m.AppVisitorsModule
          ),
        data: { title: "Visitors", breadcrumb: "Visitors" },
      },
      {
        path: "attendance-settings",
        loadChildren: () =>
          import("./modules/hospital/attendance-settings/attendance-settings.module").then(
            (m) => m.AppAttendancesettingModule
          ),
        data: { title: "Attendance Settings", breadcrumb: "Attendance Settings" },
      },
      {
        path: "nursing-station",
        loadChildren: () =>
          import("./modules/hospital/nursing-station/nursing-station.module").then(
            (m) => m.AppNursingstationModule
          ),
        data: { title: "Nursing Station", breadcrumb: "Nursing Station" },
      },
      {
        path: "treatment",
        loadChildren: () =>
          import("./modules/hospital/treatment-settings/treatment/treatment.module").then(
            (m) => m.AppTreatmentModule
          ),
        data: { title: "Treatment Settings", breadcrumb: "Treatment Settings / Treatment" },
      },
      {
        path: "disease",
        loadChildren: () =>
          import("./modules/hospital/treatment-settings/disease/disease.module").then(
            (m) => m.AppDiseaseModule
          ),
        data: { title: "Treatment Settings", breadcrumb: "Treatment Settings / Disease" },
      },
      {
        path: "reports",
        loadChildren: () =>
          import("./modules/hospital/reports/reports.module").then(
            (m) => m.AppReportsModule
          ),
        data: { title: "Reports", breadcrumb: "Reports" },
      },
      {
        path: "threshold-response",
        loadChildren: () =>
          import("./modules/hospital/threshold-response-time/threshold-response.module").then(
            (m) => m.AppThresholdResponseModule
          ),
        data: { title: "Threshold Response Time", breadcrumb: "Employee Settings / Threshold Response Time" },
      },
    ],
  },
  {
    path: "pages",
    component: AuthLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./modules/common/pages/pages.module").then(
            (m) => m.PagesModule
          ),
        data: { title: "Page Not Found", breadcrumb: "PAGES" },
      },
    ],
  },
  {
    path: "**",
    redirectTo: "pages/404",
  },
];
