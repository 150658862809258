import { Injectable } from "@angular/core";
import { Adapter } from "../../adapters";
import { ITeacherAPI } from "../../models";
import { TeacherAdapter } from "./teacher.adapter.service";

@Injectable({
    providedIn: "root"
})

export class ApiAdapter implements Adapter<ITeacherAPI> {

    constructor(private adapterPackage: TeacherAdapter) {

    }

    adapt(response: any): ITeacherAPI {

        let userData = this.adapterPackage.adapt(response.data);
        
        return {
            keyword: response.keyword,
            message: response.message,
            teacher: userData,
            pageSize: response?.total_size ?? 0,
            page: response?.page ?? 0,
        }
    }

}