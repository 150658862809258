import { Injectable } from "@angular/core";
import { Adapter } from "../../adapters";
import { User } from "../../models/";


@Injectable({
    providedIn: "root"
})
export class UserAdapter implements Adapter<User> {
   model: User;
    adapt(item: any): User {
        this.model =  new User();    
        this.model.id = item?.id ?? 0,
        this.model.name = item?.name ?? '';
        this.model.email = item?.aud ?? '';
        this.model.mobile = item?.mobile ?? '';
        this.model.token = item?.token ?? '';
        this.model.skybind = item?.rolepermission ?? '';
        this.model.roleId = item?.role_id ?? '';
        this.model.otp = item?.otp ?? '';
        return this.model;
    }
}