import { Injectable } from "@angular/core";
import { Adapter } from "../../adapters";
import { IParentAPI } from "../../models";
import { ParentAdapter } from "./parent.adapter.service";

@Injectable({
    providedIn: "root"
})

export class ApiAdapter implements Adapter<IParentAPI> {

    constructor(private adapterPackage: ParentAdapter) {

    }

    adapt(response: any): IParentAPI {

        let userData = this.adapterPackage.adapt(response.data);
        
        return {
            keyword: response.keyword,
            message: response.message,
            parent: userData,
            pageSize: response?.total_size ?? 0,
            page: response?.page ?? 0,
        }
    }

}