import { Component, OnInit, Input, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from "@angular/material/dialog";
import { IMqttMessage, MqttService } from 'ngx-mqtt';
import { ConfigService } from 'app/core/services/config.service';
import { Subscription, Observable, merge, of, pipe, from } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { Router, NavigationEnd } from '@angular/router';
import { AppLoaderService } from 'app/core/services/app-loader.service';
import { GenerateRouteService } from 'app/core/services/generate-route.service';
import { ToastService } from 'app/core/services/toast.service';
import { decodedToken, getToken } from 'app/core/helpers/token.helper';
import { filter, get } from 'lodash';
import { PORTALTYPE } from "../../../core/helpers/enum.helper";
import { MessagingService } from 'app/core/services/messaging.service';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'app/core/services/user.service';
import { NotificationsService } from 'app/modules/hospital/notifications/service/notifications.service';
import { SosNotificationComponent } from 'app/modules/hospital/reports/sos/sos-notification/list.component';
import { NomovementNotificationComponent } from 'app/modules/hospital/reports/no-movement/nomovement-notification/list.component';
import { PatientDetailsComponent } from 'app/modules/hospital/patient/patient-details/patient-details.component';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import { first, map, take } from 'rxjs/operators';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html'
})

export class NotificationsComponent implements OnInit {
  @Input() notificPanel;
  dateNumber = Date.now();
  notifications = [];
  count: number = 0;
  portalType: any;
  checkType: any = PORTALTYPE;
  notifies = [];

  // For SOS Notifications
  private subscription: Subscription;
  private message: String;
  msg: any;
  isConnected: boolean = false;
  payLoad = [];
  hospitalId
  customerId

  // For nomovement Notifications
  private subscriptionNomovement: Subscription;
  nomovementMsg: any;
  nomovementPayLoad = [];

  // For patient Notifications
  private subscriptionPatient: Subscription;
  msgPatient: any;
  payloadPatient = [];

  // light device fetch

  itemsRef: AngularFireList<any>;
  constructor(private router: Router,
    private notificationService: NotificationsService,
    private loader: AppLoaderService,
    private generateRouteService: GenerateRouteService,
    private messagingService: MessagingService,
    private toastr: ToastService,

    // SOS Notification
    public dialog: MatDialog,
    private _mqttService: MqttService,
    private config: ConfigService,
    private userServ: UserService,


    // get light device fetch
    private db: AngularFireDatabase

  ) {
    this.getNotificationList();
    this.getCurrentNotification();
    this.removeNotificationBasedOnId();
    this.reloadNotify();


    // SOS notification

    this.config.init();
    this.customerId = this.config.customerId;
    this.hospitalId = this.config.branchId

  }







  reloadNotify() {
    this.messagingService.reloadNotificationList.subscribe(res => {
      if (res) {
        this.getNotificationList();
      }
    })
  }

  ngOnInit() {
    this.router.events.subscribe((routeChange) => {
      if (routeChange instanceof NavigationEnd) {
        this.notificPanel.close();
      }
    });
    const token = decodedToken();
    this.portalType = get(token, 'type') ?? '';
    if (getToken()) {
      this.subscribeNewTopic(); // For Sos notification
      this.subscribeNewTopicForNomovement(); // For nomovement notification
      this.subscribeNewTopicForPatientDetails() // For patient details
    }
  }


  getCurrentNotification() {
    this.messagingService.currentMessage.subscribe(res => {
      if (res) {
        this.notifications.unshift(res);
      }
    })
  }

  getNotificationList() {
    let p;
    this.notificationService.getList(p)
      .subscribe(res => {
        if (res.keyword == 'success') {
          this.notifications = res.data.filter(el => el.msg_read == 0);
          this.count = this.notifications.length;
          this.generateRouteService.setQueueCount(this.count, true);
        } else {
          this.notifications = [];
        }
      });
  }

  clicked(index, row) {
    let obj = {
      id: JSON.stringify([row.notification_id]),
      db_type: JSON.stringify([row.db_type])
    }
    this.notificationService.setReadStatus(obj)
      .subscribe(res => {
      })
    this.notifications.splice(index, 1);
    this.messagingService.appendQueueCount(0);
    if (this.portalType === this.checkType.HOSPITAL) {
      this.generateRouteService.navigateRouteForSchool(row.portal, row.page, row.data);
    } else if (this.portalType === this.checkType.TEACHER) {
      this.generateRouteService.navigateRouteForTeacher(row.portal, row.page, row.data);
    } else if (this.portalType === this.checkType.PARENT) {
      this.generateRouteService.navigateRouteForParent(row.portal, row.page, row.data);
    }
  }


  removeNotificationBasedOnId() {
    this.messagingService.removeMessage.subscribe(res => {
      if (res) {
        let index = this.notifications.findIndex(el => el.notification_id == res.notification_id);
        this.notifications.splice(index, 1);
      }
    })
  }


  viewMore() {
    if (this.portalType === this.checkType.HOSPITAL) {
      this.router.navigate(['hospital/notifications/list']);
    } else if (this.portalType === this.checkType.TEACHER) {
      this.router.navigate(['teacher/notifications/list']);
    } else if (this.portalType === this.checkType.PARENT) {
      this.router.navigate(['parent/notifications/list']);
    }
  }

  clearAll(e) {
    e.preventDefault();
    this.notifications = [];
  }






  // SOS Notification


  subscribeNewTopic(): void {
    this.subscription?.unsubscribe();
    var topicName = `/hospital/${this.customerId}/${this.hospitalId}/sos`;
    this.subscription = this._mqttService.observe(topicName).subscribe((message: IMqttMessage) => {
      this.msg = message?.payload?.toString() == "Data" ? '' : JSON?.parse(message?.payload?.toString());
      console.log(this.msg, "this.msg")
      this.payLoad.unshift(this.msg);
      this.userServ.arrayOfSosSub.next(this.msg);
      if (this.payLoad?.length === 1 && getToken()) {
        this.openDialog();
      }
    });
    console.log('subscribed to topic: ' + topicName);
  }

  openDialog() {
    const dialogRef = this.dialog.open(SosNotificationComponent, {
      width: '100%',
      disableClose: true,
      panelClass: 'sos-modal'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.clearSosdata();
    });
  }



  clearSosdata() {
    this.isConnected = false;
    this.payLoad = [];
  }


  // Nomovement Notification

  subscribeNewTopicForNomovement(): void {
    this.subscriptionNomovement?.unsubscribe();
    this.nomovementPayLoad = [];
    var topicName = `/hospital/${this.customerId}/${this.hospitalId}/nomovement`;
    this.subscriptionNomovement = this._mqttService.observe(topicName).subscribe((message: IMqttMessage) => {
      this.nomovementMsg = message?.payload?.toString() == "Data" ? '' : JSON?.parse(message?.payload?.toString());
      this.nomovementPayLoad.unshift(this.nomovementMsg);
      this.userServ.arrayOfNomovementSub.next(this.nomovementMsg);
      if (this.nomovementPayLoad?.length === 1 && getToken()) {
        this.openDialogNomovement();
      }
    });
  }

  openDialogNomovement() {
    const dialogRef = this.dialog.open(NomovementNotificationComponent, {
      width: '100%',
      disableClose: true,
      panelClass: 'sos-modal'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.clearSosdataNomovement();
    });
  }



  clearSosdataNomovement() {
    this.isConnected = false;
    this.nomovementPayLoad = [];
  }



  subscribeNewTopicForPatientDetails() {
    this.payloadPatient = [];

    // this.subscriptionPatient?.unsubscribe();
    // var topicName = `/alert/3`;
    // this.subscriptionPatient = this._mqttService.observe(topicName).subscribe((message: IMqttMessage) => {
    //   this.msgPatient = message?.payload?.toString() == "Data" ? '' : JSON?.parse(message?.payload?.toString());
    //   this.payloadPatient.unshift(this.msgPatient);
    //   console.log(this.payloadPatient, "this.payloadPatient")
    //   this.userServ.arrayOfPatientSub.next(this.msgPatient);
    //   if (this.payloadPatient?.length === 1 && getToken()) {
    //     this.openDialogPatient();
    //   }
    // });
    // console.log('subscribed to topic: ' + topicName);


    // from(this.db.list('patient_detail').valueChanges()).subscribe((x) => {

    //  const object = this.payLoad.reduce(function (previous, current) {
    //   var object = previous.filter(object => object.id === current.id);
    //   if (object.length == 0) {
    //     previous.push(current);
    //   }
    //   return previous;
    // }, []);

    //   console.log(x, `response,`)
    // })
    this.itemsRef = this.db.list('patient_detail');
    this.itemsRef.valueChanges().subscribe((res) => {
      res.map((el: any) => JSON.parse(el.toString())).forEach((y) => {
        this.payloadPatient.unshift(y);
        this.userServ.arrayOfPatientSub.next(y);
        if (this.payloadPatient?.length === 1 && getToken()) {
          this.openDialogPatient();
        }
      })
    })

  }


  openDialogPatient() {
    const dialogRef = this.dialog.open(PatientDetailsComponent, {
      width: "70%",
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.clearPatientData();
    });
  }

  clearPatientData() {
    this.isConnected = false;
    this.payloadPatient = [];
  }

}
