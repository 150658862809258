import { Injectable } from "@angular/core";
import { Batch } from "app/shared/models/batch/batch.model";
import { Adapter } from "app/core/adapters";
import { ClassAdapter } from "./class.adapter.service";


@Injectable({
    providedIn: "root"
})
export class BatchAdapter implements Adapter<Batch> {
    obj: Batch;


constructor(  private adapterClass:ClassAdapter ){}

    adapt(item: any): Batch {
            let classList = [];
            if(item.class){ 
            item?.class.forEach(row => {
                classList.push(this.adapterClass.adapt(row));
            });
        }

        this.obj =  new Batch();        
        this.obj.batchId = item.batch_id
        this.obj.academicYearId = item.academic_year_id
        this.obj.academicYear = item.academic_year_name,
        this.obj.batchName = item.batch_name,
        this.obj.class = classList
        this.obj.batchName = item.batch_name,        
        this.obj.status = item.status
        return this.obj;
    }
}