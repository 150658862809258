// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiURL: "https://hospital.petalred.in/api/V1/HP/",
  imageUrl: "https://hospital.petalred.in/",
  trackingURL: "https://education.petalpurple.net/",
  // liveTrackingUrl: "https://tracking.petalred.com/",
  deviceApiURl: "https://device.petalred.com:8010/red/api/",
  evaluationToolURl: "https://evaluation.petalpurple.in/",
  noMovementUrl: "https://device.petalred.com:8010/red/api/axis/set/movement-time",
  todayTimeline: "https://device.petalred.com:8010/red/api/track/get/timeline-detail",
  subscribeHub: "https://device.petalred.com:8001/red/location/hospital/topic/subscribe",
  unsubscribeHub: "https://device.petalred.com:8001/red/location/hospital/topic/unsubscribe",
  liveTrackingUrl:"https://tracking1.petalred.com/",
  firebaseConfig: {
    apiKey: "AIzaSyAFeJV_0GC79UH2XmVg9E6QwJyB3G37ozE",
    authDomain: "petalredlightfetch.firebaseapp.com",
    databaseURL: "https://petalredlightfetch-default-rtdb.firebaseio.com",
    projectId: "petalredlightfetch",
    storageBucket: "petalredlightfetch.appspot.com",
    messagingSenderId: "964152279827",
    appId: "1:964152279827:web:2d667a72c2a277d87bd0e6",
    measurementId: "G-38PPYQZBZF"
  },

  // mqttConfig: {
  //   hostname: 'petal.tk',
  //   port: 8084,
  //   path: '/mqtt',
  //   protocol: 'wss',
  // }
  mqttConfig: {
    hostname: "device.petalred.com",
    port: 8084,
    path: "/mqtt",
    protocol: "wss",
    username : "PetalHosWssUser"  ,
    password : "PetalHoswSS@20#19!"
  },
};
